import React, { useEffect, useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import type { TFTBestItem } from "@/data-models/contentful.mjs";
import ChampionBestItems from "@/game-tft/ChampionBestItems.jsx";
import { isFutureSet, isRevivalSet } from "@/game-tft/constants.mjs";
import fetchIndividualStats from "@/game-tft/fetch-tft-champion-data.mjs";
import getFilters from "@/game-tft/get-stats-filters.mjs";
import fetchTFTStatic from "@/game-tft/tft-fetch-static-data.mjs";
import { useLocalizedChampions } from "@/game-tft/use-champions.mjs";
import useSetByParam from "@/game-tft/use-set-by-param.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function ChampionBestItemsArticle({
  champion: championKey,
  rank,
  region,
}: TFTBestItem) {
  const { tft } = useSnapshot(readState);
  const set = useSetByParam(true);
  const champions = useMemo(() => tft[set]?.champions, [tft, set]); // State dependency
  const localizedChampions = useLocalizedChampions(set); // State dependency
  const champion = useMemo(
    () => localizedChampions?.[championKey] ?? champions?.[championKey],
    [championKey, champions, localizedChampions],
  );
  const ephemralSearchParams = useMemo(() => {
    const p = new URLSearchParams();
    p.set("rank", rank);
    p.set("region", region);
    p.set("mode", "RANKED"); // Default
    p.set("api_name", championKey);
    return p;
  }, [championKey, rank, region]);
  const indivUnitFilters = useMemo(
    () =>
      getFilters(
        ephemralSearchParams,
        "indiv_units",
        isFutureSet(set),
        isRevivalSet(set),
      ) + `&api_name=${championKey}`,
    [championKey, ephemralSearchParams, set],
  );
  const indivUnitStats = useMemo(
    () => tft.stats.indiv_units[indivUnitFilters]?.[set] ?? [],
    [indivUnitFilters, set, tft.stats.indiv_units],
  );
  useEffect(() => {
    fetchTFTStatic();
    fetchIndividualStats([championKey], ephemralSearchParams);
  }, [championKey, ephemralSearchParams]);
  return (
    // Clamp this UI to 350px (as shown on the TFT champion pages)
    <div className="w-full" style={{ maxWidth: "350px" }}>
      <ChampionBestItems
        champion={champion}
        indivUnitStats={indivUnitStats}
        selectedSet={set}
      />
    </div>
  );
}
